<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="items"
			:items-per-page="-1"
			hide-default-footer
			class="selected-table">

			<template v-slot:body="props">
				<Draggable
					:list="props.items"
					tag="tbody"
					:move="handleMove"
					@end="handleDragEnd">
					<tr class="_row" v-for="(selectedTalkingPoint, index) in props.items" :key="selectedTalkingPoint.id"
						@dragstart="dragStartHandler" @dragend="dragEndHandler" @drag="dragHandler">
						<td>{{ selectedTalkingPoint.percentage }}</td>
						<td class="_row--title">{{ selectedTalkingPoint.title }}</td>
						<td>{{ selectedTalkingPoint.market.label }}</td>
						<td>{{ selectedTalkingPoint.respondentType.label }}</td>
						<td>{{ selectedTalkingPoint.topics.map((topic) => topic.label).join(', ') }}</td>
						<td>
							<v-icon @click="onDeselectRow(props.items[index])">
								mdi-close
							</v-icon>
						</td>
					</tr>
				</Draggable>
			</template>

		</v-data-table>

		<div class="drag-preview" :style="dragStyle" ref="dragPreview"></div>

	</div>

</template>

<script>
import Draggable from 'vuedraggable';

export default {
	name: 'TalkingPointsTable',

	components: {
		Draggable
	},

	props: {
		items: {
			type: Array,
			default: () => []
		}
	},

	data () {
		return {
			headers: [
				{ text: 'Volume', value: 'percentage', sortable: false, width: '6%', class: 'metaBlue--text' },
				{ text: '', value: 'title', sortable: false, width: '50%', class: 'metaBlue--text' },
				{ text: 'Market', value: 'market', sortable: false, width: '14%', class: 'metaBlue--text' },
				{
					text: 'Respondent Type',
					value: 'respondentType',
					sortable: false,
					width: '14%',
					class: 'metaBlue--text'
				},
				{
					text: 'Topics',
					value: 'topics',
					sortable: false,
					width: '10%',
					class: 'metaBlue--text'
				},
				{ text: '', value: 'action', sortable: false, width: '6%', class: 'metaBlue--text' }
			],

			futureIndex: null,
			movingIndex: null,
			currentHoverIndex: null,
			oldRelated: null,
			dragPreview: null,
			x: 0,
			y: 0
		};
	},

	computed: {
		dragStyle () {
			return {
				top: `${this.y + 8}px`,
				left: `${this.x + 16}px`
			};
		}
	},

	methods: {
		onDeselectRow (row) {
			this.$emit('removeRow', row);
		},
		dragStartHandler (e) {
			e.dataTransfer.effectAllowed = 'copyMove';
			e.dataTransfer.setData('text/plain', '');
			document.documentElement.style.cursor = '-webkit-grabbing'; // don't work

			this.dragPreview = e.target.cloneNode(true);
			this.$refs['dragPreview'].style.width = `${e.target.offsetWidth}px`;
			this.$refs['dragPreview'].appendChild(this.dragPreview);
			this.$refs['dragPreview'].style.display = 'block';
		},
		dragEndHandler (e) {
			document.documentElement.style.cursor = 'default'; // don't work
			this.dragPreview.remove();
			this.dragPreview = null;
			this.$refs['dragPreview'].style.display = 'none';
		},
		dragHandler (e) {
			this.x = e.pageX;
			this.y = e.pageY;
		},
		handleDragEnd (e) {
			if (this.currentHoverIndex === null) return;

			this.$emit('changeOrder', {
				newIndex: this.futureIndex,
				oldIndex: this.movingIndex,
				swap: true
			});

			this.oldRelated && this.oldRelated.classList.remove('_hover');
			this.movingIndex = this.futureIndex = this.currentHoverIndex = this.oldRelated = null;
		},
		handleMove (e) {
			const { index, futureIndex } = e.draggedContext;
			this.movingIndex = index;
			this.futureIndex = futureIndex;
			if (this.currentHoverIndex !== futureIndex) {
				this.oldRelated && this.oldRelated.classList.remove('_hover');
				e.related.classList.add('_hover');
				this.currentHoverIndex = futureIndex;
				this.oldRelated = e.related;
			}
			return false; // disable sort
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

._row {
  color: $gray-600;
}

td {
  &._row--title {
    height: 75px !important;
  }

  button {
    background-color: transparent !important;
    border: none !important;
    color: $meta-blue !important;

    &:hover {
      border: none !important;
    }
 }
}

.selected-table {
  border-radius: 0;
  max-height: 300px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $blue-100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $meta-blue;
  }

  tr {
    cursor: grab !important;
    background-color: $blue-100;
    border: none !important;

    &:hover {
      background-color: $blue-100  !important;

      td {
        border-radius: 0 !important;
      }
    }

    &._hover {
      background-color: $blue-300  !important;
    }

    &:first-of-type {
      td {
        border-top: 1px solid $meta-blue !important;
      }
    }

    td {
      border-bottom: 1px solid $meta-blue !important;
    }

  }
}

.drag-preview {
  position: absolute;
  background-color: transparent;
  border: 2px solid transparent;

  td {
    padding: 8px;

    &:last-of-type {
      display: none;
    }

  }
}
</style>
