function isMatchedText (text, term) {
	return text.toLowerCase().includes(term.trim().toLowerCase());
}

function getFederatedSearchFilters (searchTerm, synonymList) {
	return synonymList
		.filter((item) => item.synonym?.toLowerCase().startsWith(searchTerm?.toLowerCase()))
		.reduce((items, cur) => {
			if (items.every(item => item.filter.id !== cur.filter.id)) {
				items.push(cur);
			}
			return items;
		}, []);
}

function filterItems (items, synonyms, searchTerm) {
	if (!searchTerm) return items;

	const federatedSearchResults = getFederatedSearchFilters(searchTerm, synonyms).map((item) => item.filter.id);
	return items.filter((item) =>
		isMatchedText(item.label, searchTerm) ||
    federatedSearchResults.includes(item.id)
	);
}

module.exports = { filterItems };
