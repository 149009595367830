<template>
	<v-data-table
		:headers="headers"
		:items="items"
		id="virtual-scroll-table"
		v-scroll:#virtual-scroll-table="onScroll"
		hide-default-footer
		disable-pagination
		@click:row="$emit('select', $event)"
		@update:sort-by="$emit('sort', { type: 'by', data: $event })"
		@update:sort-desc="$emit('sort', { type: 'desc', data: $event })"
		multi-sort
		class="unselected-table">

		<template v-slot:body="{ items }">
			<tbody class="_body">
				<tr class="_row" v-for="item in items" :key="item.id" @click="$emit('select', item)">
					<td>
						<div>
							<span class="_icon">
								<v-icon>
									add
								</v-icon>
							</span>

							<span class="_percentage">{{ item.percentage }}</span>
						</div>
					</td>
					<td class="_row--title" v-html="item.highlightedTitle || item.title" ></td>
					<td> {{ item.market.label  }}</td>
					<td> {{ item.respondentType.label }}</td>
					<td> {{ item.topics.map((topic) => topic.label).join(', ') }} </td>
				</tr>
			</tbody>
		</template>

	</v-data-table>
</template>

<script>
export default {
	name: 'TalkingPointsTableUnselected',

	props: {
		items: {
			type: Array,
			default: () => []
		}
	},

	data () {
		return {
			headers: [
				{ text: 'Volume', value: 'percentage', sortable: true, width: '10%', class: 'metaBlue--text' },
				{ text: 'Talking Point', value: 'title', sortable: false, width: '56%', class: 'metaBlue--text' },
				{ text: 'Market', value: 'market', sortable: true, width: '10%', class: 'metaBlue--text' },
				{
					text: 'Respondent Type',
					value: 'respondentType',
					sortable: true,
					width: '14%',
					class: 'metaBlue--text'
				},
				{
					text: 'Topics',
					value: 'topics',
					sortable: true,
					width: '10%',
					class: 'metaBlue--text'
				}
			]
		};
	},

	methods: {
		onScroll (data) {
			this.$emit('scroll', data);
		}
	}
};
</script>

<style lang="scss" >
@import "@/scss/_colors.scss";

#virtual-scroll-table {
  // Filters height - navigation - table header heights
  max-height: calc(972px - 69px - 70px);
  overflow-y: auto;
}

.unselected-table {

  table {
    overflow: hidden;
  }

  ._row {
    cursor: pointer;
    right: 0;
    transition: all 0.3s ease-in-out;
    transition-delay: 0s;
    color: $gray-600;

    &:hover {
      right: -80px;
      transition: all 0.3s ease-in-out;
      transition-delay: 0.3s;
      background-color: #fff !important;
    }

    td {

      &:first-child {
        padding: 0;

        div {
          display: flex;
          flex-direction: row;
          height: 100%;
          align-items: center;

            ._icon {
              position: absolute;
              right: 100%;
              display: flex;
              justify-content: center;
              background-color: $meta-blue;
              height: 100%;
              width: 80px;
              padding: 25px;

            .v-icon {
              color: #fff !important;
            }
          }

          ._percentage {
            margin-left: 16px;
          }
        }

      }
    }

    ._row--title {
      height: 75px !important;
    }

  }
}

</style>

