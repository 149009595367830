<template>
	<div class="px-5 py-0">
		<div class="talking-points-list--actions ma-0 pa-0">
			<v-text-field
				class="talking-points-list--actions--search"
				:value="search"
				label="Search"
				color="metaGray"
				autocomplete="off"
				clearable
				@input="onSearchTalkingPoints"
			>
				<v-icon slot="prepend-inner" color="metaGray">
					search
				</v-icon>
			</v-text-field>
			<v-spacer></v-spacer>
			<p class="talking-points-list--actions--description ma-0">
				<span class="font-weight-bold">{{ talkingPoints.length }}</span>
				{{ ` out of ${totalTalkingPoints} found within the `}}
				<span class="font-weight-bold">{{ surveyTitle }}</span>
				{{ ' Survey with the active filter selection' }}
			</p>
			<v-tooltip bottom content-class="experience-preview-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<span
						class="material-icons-outlined rounded-circle icon-favorite"
						v-bind="attrs"
						v-on="on"
						aria-hidden="true"
						:class="{ 'icon-favorite_saved': surveySaved }"
						@click="toggleSurveySaved"
					>
						star_outline
					</span>
				</template>
				<span v-if="surveySaved">Survey added to favorites</span>
				<span v-else>Add survey to favorites</span>
			</v-tooltip>
		</div>
		<TalkingPointsTableUnselected
			:items="unselectedTalkingPoints"
			@scroll="onScrollUnselectedTable"
			@sort="onSortTalkingPoints"
			@select="onSelectTalkingPoint"
		></TalkingPointsTableUnselected>
	</div>
</template>

<script>
import TalkingPointsTableUnselected from '@/components/TalkingPointsTableUnselected';
import Ga from '@/mixins/ga';
import GaEvent from '@/constants/ga-event';
import { STEPS } from '@/store/general';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
	name: 'TalkingPointsList',

	components: {
		TalkingPointsTableUnselected
	},

	mixins: [Ga],

	computed: {
		...mapState({
			previousStep: (state) => state.general.previousStep,

			surveySaved: (state) => state.talkingPoints.surveySaved,
			surveyId: (state) => state.talkingPoints.selectedSurveyId,
			surveyTitle: (state) => state.talkingPoints.surveyTitle,
			experiences: (state) => state.talkingPoints.experiences,

			selectedMarkets: (state) => state.talkingPoints.selectedMarkets,
			selectedRespondentTypes: (state) => state.talkingPoints.selectedRespondentTypes,
			selectedTopics: (state) => state.talkingPoints.selectedTopics,

			languages: (state) => state.talkingPoints.languages,

			search: (state) => state.talkingPoints.search,
			talkingPoints: (state) => state.talkingPoints.talkingPoints,
			totalTalkingPoints: (state) => state.talkingPoints.totalTalkingPoints,
			loadingTalkingPoints: (state) => state.talkingPoints.loadingTalkingPoints,
			previousOffset: (state) => state.talkingPoints.previousOffset,
			sortColumns: (state) => state.talkingPoints.sortColumns
		}),
		...mapGetters('talkingPoints', [
			'selectedExperiencesMarkets'
		]),
		selectedFilters () {
			return [...this.selectedMarkets, ...this.selectedRespondentTypes, ...this.selectedTopics];
		},
		unselectedTalkingPoints () {
			const exps = this.experiences.map((item) => item.id);
			return this.talkingPoints.filter((tp) => !exps.includes(tp.id));
		}
	},

	async beforeMount () {
		if (this.previousStep !== STEPS.REPORT) {
			this.loadSurveyInfos();
			this.loadIcons();
			this.addRecentSurveyDocument(this.surveyId);
		}
	},

	watch: {
		selectedFilters () {
			this.setSelectionSaved(false);
			this.resetTalkingPoints();
		},
		selectedExperiencesMarkets () {
			const USEnglishIndex = this.languages.findIndex(e => e.id === 'en');
			if (USEnglishIndex > - 1) {
				const copy = [...this.languages];
				copy[USEnglishIndex].name = 'US English';
				this.setLanguages(copy);
			}
		}
	},

	methods: {
		...mapMutations('talkingPoints', [
			'setSurveyTitle',
			'setDefaultTexts',
			'setLanguages',
			'setSearch',
			'setPreviousOffset',
			'setSortColumns',
			'setSelectionSaved'
		]),
		...mapActions('talkingPoints', [
			'updateTalkingPoints',
			'handleToggleTalkingPoint',
			'loadSurveyInfos',
			'loadIcons',
			'toggleSurveySaved',
			'resetTalkingPoints'
		]),
		...mapActions('home', [
			'addRecentSurveyDocument'
		]),

		async onScrollUnselectedTable (e) {
			// elements to trigger reload only when scrollbar reaches bottom
			const { scrollTop, scrollHeight, clientHeight } = e?.target || {};
			const currentOffset = this.talkingPoints?.length;
			if (
				!this.loadingTalkingPoints &&
				(
					!this.totalTalkingPoints ||
					!this.talkingPoints.length ||
					(
						this.talkingPoints.length < this.totalTalkingPoints &&
						scrollTop + clientHeight >= 0.75 * scrollHeight &&
						currentOffset !== this.previousOffset
					)
				)
			) {
				this.setPreviousOffset(currentOffset);
				await this.updateTalkingPoints(this.talkingPoints);
			}
		},

		onSearchTalkingPoints (search) {
			this.setSearch(search);

			clearTimeout(this.handleLaunch);
			this.handleLaunch = setTimeout(() => {
				this.resetTalkingPoints();
			}, 300);
		},

		onSortTalkingPoints ({ type, data }) {
			this.updateSortColumns(type, data);
		},
		async updateSortColumns (eventType, eventData) {
			if (eventType === 'by') {
				this.setSortColumns({});
				for (const column of eventData) {
					this.setSortColumns({ ...this.sortColumns, [column]: 1 });
				}
			} else {
				let i = 0;
				for (const column of Object.keys(this.sortColumns)) {
					if (eventData[i] !== undefined) {
						this.setSortColumns({ ...this.sortColumns, [column]: eventData[i] ? 1 : -1 });
					} else this.setSortColumns({ ...this.sortColumns, [column]: undefined });
					i += 1;
				}

				this.resetTalkingPoints();
			}
		},

		onSelectTalkingPoint (row) {
			this.sendGaEvent(GaEvent.talkingPoint, `${row.market.label}|${row.respondentType.label}|${this.surveyTitle}`);
			this.handleToggleTalkingPoint([row]);
		}
	}
};
</script>

<style lang="scss">
.v-input__icon--clear button {
  background-color: transparent !important;
  border: none !important;

  &:hover {
    border: none !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.talking-points-list--actions {
  display: flex;
  align-items: center;

  &--description {
    display: inline;
    font-size: 0.8rem;
    color: $gray-600;
  }
}

.icon-favorite {
  margin-left: 30px;
  cursor: pointer;
  font-size: 18px;
  border: 1px solid $meta-blue !important;
  color: $meta-blue !important;
  background-color: white !important;
}

.icon-favorite_saved {
  color: white !important;
  background-color: $meta-blue !important;
}
</style>
