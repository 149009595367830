<template>

	<div class="select">

		<v-menu open-on-hover close-delay="150" v-bind="$attrs" :id="`experience-ui-select-${icon}`" :attach="`#experience-ui-select-${icon}`">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					icon
					x-small
					:class="_class"
					:ripple="false">
					<i :class="icon" aria-hidden="true"></i>
				</v-btn>
			</template>

			<v-list color="#fff" elevation="0" min-width="200px">
				<v-list-item v-for="(item, index) in items" :key="index"
					@click="$emit('onselect', item)">
					{{ getLabel(item) }}
				</v-list-item>
			</v-list>

		</v-menu>

	</div>

</template>

<script>

export default {
	props: {
		items: {
			type: Array,
			required: true
		},
		itemText: {
			type: String,
			default: ''
		},
		icon: {
			type: String,
			required: true
		},
		_class: {
			type: String
		}
	},

	methods: {
		getLabel (item) {
			return this.itemText ? item[this.itemText] : item;
		}
	}
};

</script>

<style  lang="scss" scoped>
  .v-menu {
    display: block;
    position: static;

    .v-menu__content {
      position: absolute;
      left: 0 !important;
      top: 48px !important;
    }
  }
</style>

<style lang="scss">

@import "@/scss/_colors.scss";

.select {
  width: 100%;
}

.v-list-item {
  text-transform: uppercase;
}

#experience-ui-select-icon-UI-experience-download {
	> div {
		transform: translateX(-175px);
	}
}

</style>
