<template>
	<div class="icon__container" :class="{'_hovered': isHovered}">
		<div>
			<span class="icon__container_icon material-icons-outlined rounded-circle">
				description
			</span>

			<span class="icon__container_count text-caption">
				{{ count }}
			</span>
		</div>
	</div>
</template>

<script>

export default {
	name: 'CartIcon',

	props: {
		count: {
			type: Number,
			default: 0
		},
		isHovered: {
			type: Boolean,
			default: false
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.icon__container {
  position: relative;
  border-left: 1px solid $light-gray-03;
  padding: 9px 18px 0px 18px;
  margin-top: -2px;

  & > div {
    display: flex;
    padding: 8px 8px 17px 8px;
    margin-bottom: -2px;
  }

  &._hovered {

    & > div {
      background-color: #fff;
      padding: 6px 6px 17px 6px;
      border: 2px solid $meta-blue;
      border-bottom: none;
    }

    &::after {
      content: "";
      height: 4px;
      margin-left: 1.5px;
      width: calc(36px + 12px + 0.5px);
      color: #fff;
      display: block;
      position: absolute;
      bottom: -2px;
      background-color: #fff;
      z-index: 999;
    }

    .icon__container_count {
      top: 1px;
      right: 4px;
    }
  }

  .icon__container_icon {
    font-size: 36px;
    transform: rotateY(180deg);
    color: $gray-600;
  }

  .icon__container_count {
    position: absolute;
    color: #fff;
    text-align: center;
    top: 3px;
    right: 6px;
    height: 20px;
    width: 20px;
    background-color: $meta-blue;
    border-radius: 50%;
  }
}
</style>
