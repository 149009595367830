<template>

	<v-btn @click="$emit('click')" class="rounded-0 font-weight-bold" :class="getButtonClass">
		<v-icon v-if="status === BUTTON_STATUS.VALID" class="mr-1">check</v-icon>
		{{ title }}
	</v-btn>

</template>

<script>

export const BUTTON_STATUS = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
	VALID: 'VALID'
};

export default {
	name: 'BuildExperienceStepButton',

	props: {
		title: {
			type: String,
			required: true
		},
		status: {
			type: String,
			default: BUTTON_STATUS.INACTIVE
		}
	},

	data: function () {
		return {
			BUTTON_STATUS
		};
	},

	computed: {
		getButtonClass () {
			if (this.status === BUTTON_STATUS.ACTIVE) return 'button_active';
			if (this.status === BUTTON_STATUS.INACTIVE) return 'button_inactive';
			if (this.status === BUTTON_STATUS.VALID) return 'button_valid';

			return 'button_inactive';
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

button {

  .v-icon {
    font-size: 18px;
  }

  &.button_active {
    border: none !important;
    background-color: $blue-100  !important;
    color: $meta-blue  !important;
  }

  &.button_inactive {
    color: $gray-300  !important;
    background-color: #fff !important;
    border: 1px solid $gray-300  !important;
  }

  &.button_valid {
    color: $meta-blue  !important;
    background-color: #fff !important;
    border: 1px solid $meta-blue  !important;
  }
}
</style>
