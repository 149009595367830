import GaEvent from '@/constants/ga-event';

export default {
	methods: {
		async sendGaEvent (eventAction, eventCategory, eventLabel, eventValue) {
			const eventObject = Object.create({});
			if (eventCategory) eventObject['event_category'] = eventCategory;
			if (eventLabel) eventObject['event_label'] = eventLabel;
			if (eventValue) eventObject['value'] = eventValue;
			await this.$gtag.event(eventAction, eventObject);
		},

		async sendGaExportEvent (eventAction, eventLabel, eventValue) {
			await this.sendGaEvent(eventAction, GaEvent.export, eventLabel, eventValue);
		}
	}
};
