<template>
	<v-dialog
		:value="showDialog"
		@click:outside="removePopup"
		width="650"
		height="181"
	>
		<v-card>
			<v-card-title class="ma-0 pa-0">
				<v-spacer></v-spacer>
				<v-menu
					bottom
					left
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							@click="removePopup"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</template>
				</v-menu>
			</v-card-title>
			<component
				:is="contentComponent"
				v-bind="componentProps"
				@removePopup="removePopup"
				@popupDone="popupDone"
				@popupAction="popupAction"
			></component>
		</v-card>
	</v-dialog>
</template>


<script>
import MethodologyDownloadPopup from '@/components/MethodologyDownloadPopup.vue';
import UsageForm from '@/components/UsageForm.vue';


export default {
	name: 'GenericPopup',
	components: {
		MethodologyDownloadPopup,
		UsageForm
	},
	props: {
		showDialog: {
			type: Boolean,
			required: true
		},
		contentComponent: {
			type: String,
			required: true
		},
		componentProps: {
			type: Object
		}
	},
	methods: {
		popupDone (arg) {
			this.$emit('popupDone', arg);
		},
		removePopup (arg) {
			this.$emit('removePopup', arg);
		},
		popupAction (arg) {
			this.$emit('popupAction', arg);
		}
	}
};
</script>
