<template>

	<v-form @submit.prevent ref="form">
		<v-container fluid>
			<v-row>
				<div class="message">
					Would you also like to download the methodology file(s) associated to this survey?
				</div>
			</v-row>
			<div class="actions">
				<v-btn
					class="action"
					@click="download">
					<span class="button-message">Download documents</span>
				</v-btn>
				<v-btn
					class="action remove"
					@click="removeFromViewAndProceed">
					<span class="button-message without-message">Proceed without</span>
				</v-btn>
			</div>
		</v-container>
	</v-form>

</template>

<script>

export default {
	name: 'MethodologyDownloadPopup',

	computed: {
		label () {
			return this.filter.originalLabel || this.filter.label;
		}
	},

	methods: {
		download () {
			this.$emit('popupDone', true);
		},
		removeFromViewAndProceed () {
			this.$emit('popupDone');
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";


.actions {
	button.action {
		margin: 20px 10px 10px 10px;
		background-color: $green-01;
	}
}

.message {
	font-size: 18px;
	font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
	color: $green-06;
	padding: 12px 30px 32px 30px;
	text-align: justify;
}

.button-message {
	font-family: 'Optimistic Text', 'Noto Sans Khmer', sans-serif;
	font-size: 1em;
	text-align: left;
	color: $green-06;
}

.remove {
	background-color: $gray-100 !important;
}

.without-message {
	color: $gray-600;
}

</style>
