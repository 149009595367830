<template>
	<div class="filter-header">
		<div v-if="!search.activated" class="filter-header-label pa-2 mb-1">
			<v-icon color="metaBlue" v-if="value" @click="closeMenu">mdi-chevron-up</v-icon>
			<v-icon color="metaBlue" v-else @click="openMenu">mdi-chevron-down</v-icon>
			<span class="metaBlue--text font-weight-bold">{{ label }}</span>
			<v-icon color="metaBlue" class="filter-header-label-search" @click="openSearch">mdi-magnify</v-icon>
		</div>
		<div v-else class="filter-header-search pa-2 mb-1">
			<v-icon color="metaBlue">mdi-magnify</v-icon>
			<v-text-field dense flat hide-details class="ma-0 pa-0" :value="search.text" @input="onInputSearchText($event)"></v-text-field>
			<v-icon color="metaBlue" small @click="closeSearch">mdi-close</v-icon>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FilterHeader',

	props: {
		value: {
			type: Boolean,
			default: false
		},
		label: {
			type: String
		},
		secondaryColor: {
			type: String
		}
	},

	data () {
		return {
			search: {
				activated: false,
				text: ''
			}
		};
	},

	methods: {
		openMenu () {
			this.$emit('input', true);
		},
		closeMenu () {
			this.$emit('input', false);
		},
		openSearch () {
			if (this.value) this.search.activated = true;
		},
		closeSearch () {
			this.search.activated = false;
			this.search.text = '';
			this.$emit('search', '');
		},
		onInputSearchText (text) {
			this.search.text = text;
			this.$emit('search', text);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.filter-header {
  .v-icon {
    background-color: transparent !important;
    border: none !important;
  }

  .filter-header-label {
    display: flex;
    background-color: $blue-100;
    &:hover {
      background-color: $blue-300;
    }

    .filter-header-label-search {
      margin-left: auto;
    }
  }

  .filter-header-search {
    display: flex;
    background-color: $blue-100;
  }
}
</style>
