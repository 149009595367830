const { mapMutations } = require('vuex');

export default {
	mounted () {
		this.setIsFooterShown(false);
	},

	destroyed () {
		this.setIsFooterShown(true);
	},

	methods: {
		...mapMutations('general', [
			'setIsFooterShown'
		])
	}
};
