<template>

	<v-menu open-on-hover close-delay="150" v-bind="$attrs" :id="`export-report`" :attach="`#js-nav__right-actions`">
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on">

				<BuildExperienceActionButton :title="title" :icon="icon" />

			</div>
		</template>

		<v-list color="#fff" elevation="0" min-width="200px">
			<v-list-item v-for="(format, index) in formats" :key="index" @click="$emit('click', format)" >
				{{ format }}
			</v-list-item>
		</v-list>

	</v-menu>

</template>

<script>

import BuildExperienceActionButton from './BuildExperienceActionButton.vue';

export default {
	name: 'BuildExperienceExportButton',

	components: { BuildExperienceActionButton },

	data: function () {
		return {
			formats: ['PDF', 'Image']
		};
	},

	props: {
		title: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		}
	}

};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

#export-report {
  display: none !important;
}

.v-menu__content {
  position: absolute;
   // Build experience navigation header height
  top: 69px !important;
  left: 0 !important;
}
</style>
