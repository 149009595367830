<template>
	<div class="filter-group-header">
		<div class="filter-group-header-action px-2 py-4 d-flex align-center">
			<template v-if="!showItems">
				<v-icon v-if="!selectedFilters.length" color="metaGray">mdi-tune</v-icon>
				<v-hover v-slot="{ hover }" v-else>
					<v-avatar color="metaBlue" size="24">
						<span v-if="!hover" class="white--text text-button">{{ selectedFilters.length }}</span>
						<v-icon v-else class="filter-group-header-action-icon" @click="showItems = true">mdi-chevron-down</v-icon>
					</v-avatar>
				</v-hover>
			</template>
			<v-avatar v-else size="24">
				<v-icon class="filter-group-header-action-icon" @click="showItems = false">mdi-chevron-up</v-icon>
			</v-avatar>

			<span class="filter-group-header-title px-2 font-weight-bold">{{ label }}</span>

			<v-tooltip v-if="!showItems" bottom color="gray100" content-class="metaGray--text" :z-index="102">
				<template v-slot:activator="{ on, attrs }">
					<v-hover v-slot="{ hover }">
						<v-icon
							v-bind="attrs"
							v-on="on"
							:color="hover ? 'gray600' : 'gray300'"
						>
							mdi-information-outline
						</v-icon>
					</v-hover>
				</template>
				<component :is="filtersGuide" />
			</v-tooltip>
			<v-btn v-else class="filter-group-header-action-button" @click="$emit('clearAll')">Clear All</v-btn>
			<v-tooltip v-if="showSaveSelection" bottom content-class="experience-preview-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						v-bind="attrs"
						v-on="on"
						class="ml-2 rounded-circle icon-selectable"
						:class="{ 'icon-selectable_selected': selectionSaved }"
						@click="$emit('toggleSaveSelection')"
					>
						mdi-bookmark-outline
					</v-icon>
				</template>
				<span v-if="selectionSaved">Filter selection saved</span>
				<span v-else>Save this filter selection</span>
			</v-tooltip>
		</div>

		<div v-if="showItems" class="filter-group-header-chips px-2 pt-2 pb-4">
			<Chip
				v-for="item in selectedFilters"
				:key="item.id"
				:label="item.label"
				@close="$emit('clearFilter', item.id)"
			></Chip>
		</div>
	</div>
</template>

<script>
import SurveysFiltersGuide from '@/components/SurveysFiltersGuide';
import TalkingPointsFiltersGuide from '@/components/TalkingPointsFiltersGuide';
import FilesFiltersGuide from '@/components/FilesFiltersGuide';
import Chip from '@/components/Chip';

export default {
	name: 'FilterGroupHeader',

	components: {
		SurveysFiltersGuide,
		TalkingPointsFiltersGuide,
		FilesFiltersGuide,
		Chip
	},

	props: {
		label: {
			type: String
		},
		selectedFilters: {
			type: Array,
			default: () => []
		},
		showSaveSelection: {
			type: Boolean,
			default: false
		},
		selectionSaved: {
			type: Boolean,
			default: false
		},
		filtersGuide: {
			type: String,
			required: true
		}
	},

	data () {
		return {
			showItems: false
		};
	},

	watch: {
		selectedFilters () {
			if (!this.selectedFilters.length) this.showItems = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.filter-group-header {
  .filter-group-header-action {
    height: 68px;
  }

  .filter-group-header-action-button {
    height: 24px;
    padding: 4px;
    margin-bottom: 4px;
    border-radius: 0;
    border-color: $gray-600 !important;
    background-color: white !important;
    color: $gray-600 !important;
    font-size: 1rem;
  }

  .filter-group-header-action-icon {
    font-size: 16px;
    color: white !important;
    background-color: $meta-blue !important;
  }

  .filter-group-header-title {
    color: $meta-gray;
  }

  .icon-selectable {
    font-size: 18px;
    border: 1px solid $meta-blue !important;
    color: $meta-blue !important;
    background-color: white !important;

    &_selected {
      color: white !important;
      background-color: $meta-blue !important;
    }
  }

  .filter-group-header-chips {
    border-top: 1px solid $light-gray-03;
  }
}
</style>
