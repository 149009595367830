<template>
	<div class="one-group-select">
		<FilterHeader
			v-model="showItems"
			:label="label"
			@search="search = $event"
		></FilterHeader>
		<div class="menu mt-2" v-if="showItems">
			<CheckList
				v-model="selectedItems"
				:items="filteredItems"
				:activeItems="filteredActiveItems"
				@input="$emit('input', selectedItems)"
				@change="$emit('change')"
				@sendEvent="$emit('sendEvent', $event)"
			></CheckList>
		</div>
	</div>
</template>

<script>
import FilterHeader from './FilterHeader';
import CheckList from './CheckList';

const { filterItems } = require('@/utils/search');

export default {
	name: 'OneGroupSelect',

	components: {
		FilterHeader,
		CheckList
	},

	props: {
		value: {
			type: Array
		},
		label: {
			type: String
		},
		items: {
			type: Array,
			default: () => []
		},
		activeItems: {
			type: Array,
			default: () => []
		},
		synonyms: {
			type: Array,
			default: () => []
		}
	},

	data () {
		return {
			showItems: true,
			search: '',
			selectedItems: []
		};
	},

	computed: {
		filteredItems () {
			return filterItems(this.items, this.synonyms, this.search);
		},
		filteredActiveItems () {
			return filterItems(this.activeItems, this.synonyms, this.search);
		}
	},


	mounted () {
		if (this.value) this.selectedItems = this.value;
	},

	watch: {
		value () {
			if (this.value) this.selectedItems = this.value;
		}
	}
};
</script>

<style lang="scss" scoped>
.one-group-select {
  .menu {
    max-height: 242px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
  }
}
</style>
