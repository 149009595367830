<template>

	<v-card class="card mx-auto" outlined tile flat>

		<v-skeleton-loader
			type="card"
			height="350"
			v-if="loading"
		></v-skeleton-loader>

		<template v-else>

			<v-img
				eager
				class="white--text align-end"
				:lazy-src="`https://picsum.photos/id/11/10/6`"
				:src="`/api/v1/surveys/thumbnail?surveyId=${survey.id}`">
				<v-card-title>
					<h2 class="font-weight-medium">
						{{ survey.title }}
					</h2>
				</v-card-title>
			</v-img>

			<div class="card__body d-flex flex-column justify-space-between">

				<div>

					<v-card-subtitle class="pb-1 caption">
						<span> {{ survey.date }} </span>
						<span v-if="survey.wave"> Wave {{ survey.wave }} </span>
						<span v-if="survey.talkingPointsTotal">
							<span>{{ numberWithCommas(survey.talkingPointsTotal) }}</span>
							Talking Points
						</span>
					</v-card-subtitle>

					<v-card-text class="body-2 card__body__description">
						<p>{{ survey.description }}</p>
					</v-card-text>

				</div>

				<v-card-actions>
					<a @click="onSelectSurvey({ surveyId: survey.id, surveyTitle: survey.title })"
						class="black--text font-weight-normal d-flex">
						<v-icon class="black--text material-icons-round rounded-circle v-card__actions__arrow">
							arrow_forward
						</v-icon>

						<span class="hover__underline ml-1">
							Select Talking Points
						</span>
					</a>
				</v-card-actions>

			</div>

		</template>

	</v-card>

</template>

<script>
const { numberWithCommas } = require('@/utils/helpers');

export default {

	props: {
		survey: {
			type: Object
		},
		loading: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		numberWithCommas,
		onSelectSurvey (survey) {
			this.$emit('onSelectSurvey', survey);
		}
	}

};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.v-card__title h2 {
  font-size: clamp(1.38rem, calc(0.14rem + 1.92vw), 1.88rem);
  line-height: 1.2em;
}


.v-card .v-image {
  aspect-ratio: 15/10 !important;
}

.card__body {
  color: $gray-600;
  flex-grow: 1;

  .card__body__description p {
    margin-bottom: 0;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.v-card__title h2 {
  filter: drop-shadow(0 1px 18px rgba(0,0,0,1)) drop-shadow(0 1px 2px rgba(0,0,0,0.5));
  word-break: break-word;
}

.v-card__subtitle span {
  font-size: 0.80rem !important;
  position: relative;
  line-height: 1em;

  &~span {
    padding-left: 11px;
  }

  &:last-child::after {
    display: none;
  }

  &::after {
    content: "";
    height: 0.6em;
    width: 1px;
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: -0.3em;
    background-color: $gray-300;
  }
}

.v-card__actions {
  padding: 16px !important;

  .v-card__actions__arrow {
    transform: scale(0.8);
    border: 1px solid #000
  }

}
</style>
