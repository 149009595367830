var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-group-header"},[_c('div',{staticClass:"filter-group-header-action px-2 py-4 d-flex align-center"},[(!_vm.showItems)?[(!_vm.selectedFilters.length)?_c('v-icon',{attrs:{"color":"metaGray"}},[_vm._v("mdi-tune")]):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-avatar',{attrs:{"color":"metaBlue","size":"24"}},[(!hover)?_c('span',{staticClass:"white--text text-button"},[_vm._v(_vm._s(_vm.selectedFilters.length))]):_c('v-icon',{staticClass:"filter-group-header-action-icon",on:{"click":function($event){_vm.showItems = true}}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3660959865)})]:_c('v-avatar',{attrs:{"size":"24"}},[_c('v-icon',{staticClass:"filter-group-header-action-icon",on:{"click":function($event){_vm.showItems = false}}},[_vm._v("mdi-chevron-up")])],1),_c('span',{staticClass:"filter-group-header-title px-2 font-weight-bold"},[_vm._v(_vm._s(_vm.label))]),(!_vm.showItems)?_c('v-tooltip',{attrs:{"bottom":"","color":"gray100","content-class":"metaGray--text","z-index":102},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":hover ? 'gray600' : 'gray300'}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)})]}}],null,false,1787157552)},[_c(_vm.filtersGuide,{tag:"component"})],1):_c('v-btn',{staticClass:"filter-group-header-action-button",on:{"click":function($event){return _vm.$emit('clearAll')}}},[_vm._v("Clear All")]),(_vm.showSaveSelection)?_c('v-tooltip',{attrs:{"bottom":"","content-class":"experience-preview-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 rounded-circle icon-selectable",class:{ 'icon-selectable_selected': _vm.selectionSaved },on:{"click":function($event){return _vm.$emit('toggleSaveSelection')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-bookmark-outline ")])]}}],null,false,4157170171)},[(_vm.selectionSaved)?_c('span',[_vm._v("Filter selection saved")]):_c('span',[_vm._v("Save this filter selection")])]):_vm._e()],2),(_vm.showItems)?_c('div',{staticClass:"filter-group-header-chips px-2 pt-2 pb-4"},_vm._l((_vm.selectedFilters),function(item){return _c('Chip',{key:item.id,attrs:{"label":item.label},on:{"close":function($event){return _vm.$emit('clearFilter', item.id)}}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }