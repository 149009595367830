<template>
	<v-menu
		:value="isTalkingPointsCartOpen"
		open-on-hover
		bottom
		offset-y
		max-width="90%"
		min-width="70%"
		attach="#js-nav--container"
		:close-on-content-click="false"
		:disabled="isCartDisabled"
	>
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on">
				<CartIcon :count="experiencesCount" :isHovered="attrs['aria-expanded'] === 'true' && !isCartDisabled"></CartIcon>
			</div>
		</template>

		<v-card>
			<TalkingPointsTableSelected
				:items="experiences"
				@changeOrder="swapExperiences"
				@removeRow="onRemoveRow"
			></TalkingPointsTableSelected>

			<div class="d-flex justify-end mt-4">
				<BuildExperienceActionButton
					v-bind="buildExperienceActionButtonProps"
					v-on="buildExperienceActionButtonProps" />
			</div>
		</v-card>
	</v-menu>
</template>

<script>
import CartIcon from '@/components/CartIcon';
import TalkingPointsTableSelected from '@/components/TalkingPointsTableSelected';
import BuildExperienceActionButton from './BuildExperienceActionButton.vue';
import { STEPS } from '@/store/general';

const { mapState, mapMutations } = require('vuex');

export default {
	name: 'BuildExperienceSelectedTalkingPointsButton',

	components: {
		CartIcon,
		TalkingPointsTableSelected,
		BuildExperienceActionButton
	},

	watch: {
		experiences: function () {
			// To make sure the menu is closed before being disabled
			if (!this.experiences.length) {
				setTimeout(() => this.isCartDisabled = true, 0);
			} else {
				this.isCartDisabled = false;
			}
		}
	},

	data: function () {
		return {
			isCartDisabled: undefined
		};
	},

	mounted: function () {
		this.isCartDisabled = Boolean(!this.experiences.length);
	},

	computed: {
		...mapState({
			experiences: (state) => state.talkingPoints.experiences,
			activeStep: (state) => state.general.activeStep,
			isTalkingPointsCartOpen: (state) => state.talkingPoints.isTalkingPointsCartOpen
		}),
		experiencesCount: function () {
			return this.experiences.length || 0;
		},
		buildExperienceActionButtonProps: function () {
			if (this.activeStep === STEPS.TALKING_POINTS) {
				return {
					title: 'Proceed to Report',
					icon: 'arrow_forward',
					click: () => this.changeStep(STEPS.REPORT)
				};
			}

			return {
				title: 'Return to Select Talking Points',
				icon: 'arrow_back',
				click: () => this.changeStep(STEPS.TALKING_POINTS)
			};
		}
	},

	methods: {
		...mapMutations('general', [
			'changeStep'
		]),
		...mapMutations('talkingPoints', [
			'swapExperiences',
			'removeTalkingPoint'
		]),

		onRemoveRow (row) {
			this.removeTalkingPoint(row.id);
		}
	}
};

</script>

<style lang="scss" scoped>
  @import "@/scss/_colors.scss";

  .v-menu {
    display: none !important;
  }

  .v-sheet.v-card {
    box-shadow: none !important;
  }

  .v-menu__content {
    left: unset !important;
    right: 18.5px !important;
    width: 1200px;
    padding: 24px;
    padding-bottom: 16px;
    margin-top: 2px;
    background-color: #fff;
    border: 2px solid $meta-blue;
    box-shadow: none;
  }
</style>
