<template>

	<div>

		<v-snackbar :value="showError" timeout="2000" color="error">
			An error occured.

			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="setShowError(false)">
					Close
				</v-btn>
			</template>
		</v-snackbar>

		<LoadingSnackbar :isLoading="showProcessing" text="Processing the generation of the export." />

		<v-snackbar right top :value="doneDownloading" @input="setDoneDownloading(false)" color="white" light timeout="2000">
			<v-icon color="metaBlue">mdi-check-circle-outline</v-icon>
			<span style="color: $meta-blue; padding: 3px 0 0 10px;">Download successful</span>
		</v-snackbar>

		<DialogConfirm :showDialog="talkingPointExport.showDialog"
			message="Would you like to export the talking point into your clipboard ?"
			@cancel="changeTalkingPointExportShowDialog(false)" @confirm="exportSingleTalkingPoint" />

		<generic-popup :showDialog="methodologyDownloadPopup" :contentComponent="'MethodologyDownloadPopup'"
			@popupDone="methodologyDownloadDone" @removePopup="closeDialog" />

		<generic-popup :showDialog="usageFormPopup" :contentComponent="'UsageForm'"
			:componentProps="{ 'markets': selectedExperiencesMarketObjects }" @popupDone="usageFormDone"
			@removePopup="closeDialog" />

	</div>

</template>

<script>
import DialogConfirm from '@/components/DialogConfirm';
import GenericPopup from '@/components/GenericPopup';
import LoadingSnackbar from './LoadingSnackbar.vue';

const { mapState, mapMutations, mapActions, mapGetters } = require('vuex');

export default {
	name: 'BuildExperiencePopups',

	components: {
		GenericPopup,
		DialogConfirm,
		LoadingSnackbar
	},

	computed: {
		...mapState({
			talkingPointExport: (state) => state.talkingPoints.talkingPointExport,

			methodologyDownloadPopup: (state) => state.report.methodologyDownloadPopup,
			usageFormPopup: (state) => state.report.usageFormPopup,
			showError: (state) => state.report.showError,
			showProcessing: (state) => state.report.showProcessing,
			doneDownloading: (state) => state.report.doneDownloading

		}),
		...mapGetters('talkingPoints', [
			'selectedExperiencesMarketObjects'
		])
	},
	methods: {
		...mapActions('report', [
			'methodologyDownloadDone',
			'usageFormDone',
			'closeDialog',
			'exportSingleTalkingPoint'
		]),
		...mapMutations('talkingPoints', [
			'changeTalkingPointExportShowDialog'
		]),
		...mapMutations('report', [
			'setDoneDownloading',
			'setShowError'
		])
	}

};

</script>
