const { isState } = require('@/utils/data');

function removeArrayItem (array, index) {
	return [...array.slice(0, index), ...array.slice(index + 1)];
}

function removeItemFromLists (id, lists) {
	const updatedList = {};
	for (const key in lists) {
		const list = lists[key];
		const matchedItemIndex = list.findIndex((item) => item.id === id);
		if (matchedItemIndex > -1) {
			updatedList[key] = removeArrayItem(list, matchedItemIndex);
			break;
		}
	}
	return { ...lists, ...updatedList };
}

function flattenMarkets (categories) {
	return categories
		.map((c) => c.items).flat()
		.map((country) => {
			if (country.items) return [country, ...country.items];
			return [country];
		}).flat()
		.sort((a, b) => a.label > b.label ? 1 : -1);
}

function formatMarkets (markets) {
	return flattenMarkets(markets);
}

function formatActiveMarkets (activeMarkets, selectedMarkets) {
	if (!selectedMarkets.length) {
		return flattenMarkets(activeMarkets);
	}
	if (selectedMarkets.every((market) => isState(market))) {
		const countryName = selectedMarkets[0].countryName;
		return activeMarkets
			.map((c) => c.items).flat()
			.find((country) => country.countryName === countryName)?.items;
	}
	return activeMarkets.map((c) => c.items).flat();
}

module.exports = { removeItemFromLists, formatMarkets, formatActiveMarkets };
