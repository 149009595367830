<template>
	<v-container class="build-experience-tab__container" no-gutters fluid pa-0>

		<v-row no-gutters>

			<v-col class="h-100" cols="12" sm="2">
				<div class="h-100 d-flex flex-column py-4 preview__container">
					<Experience
						v-if="translatedExperiences.length"
						class="thumbnails"
						v-model="nbSlides"
						:rtl="selectedLanguage.writing === 'RTL'"
						:texts="texts"
						:experiences="translatedExperiences"
						:is-landscape="isLandscapeMode"
						:mapClass="mapClass"
						:icons="icons"
						:color="color"
						:has-icons="hasIcons"
						:is-internal="isInternal"
						:nbPerSlide="nbPerSlide"
						:selectedSlide="selectedSlide"
						:isEditMode="false"
						showSlideNumber
						markSelectedSlide
						@click:slide="setSelectedSlide" />

					<div class="thumbnails mt-4">
						<div class="slides">
							<div class="thumbnail__empty">
								<p>Additionnal pages will</p>
								<p>be displayed here</p>
								<p>when you add more</p>
								<p>Talking Points</p>
							</div>
						</div>
					</div>

				</div>

			</v-col>

			<v-col class="h-100" cols="12" sm="10">

				<div class="h-100 d-flex flex-column experience__container">

					<ExperienceHeader :mapClass="mapClass" :isEditMode="isEditMode" />

					<div class="frame__container" :class="{ 'preview-mode': !isEditMode }">

						<div v-if="isRestoringReportPending" class="mt-16">
							<Spinner size="48" />
						</div>

						<Experience
							v-else
							class="frame my-4"
							v-model="nbSlides"
							:rtl="selectedLanguage.writing === 'RTL'"
							:texts="texts"
							:experiences="translatedExperiences"
							:is-landscape="isLandscapeMode"
							:mapClass="mapClass"
							:icons="icons"
							:color="color"
							:has-icons="hasIcons"
							:is-internal="isInternal"
							:nbPerSlide="nbPerSlide"
							:selectedSlide="selectedSlide"
							:isEditMode="isEditMode"
							hideUnselectedSlides
							@goBack="goToTalkingPointsList"
							@changeIcon="changeIcon"
							@rightClick="initiateSingleTalkingPoint" />
					</div>

				</div>

			</v-col>

		</v-row>

	</v-container>

</template>

<script>

import Experience from '@/components/Experience';
import ExperienceHeader from './ExperienceHeader.vue';
import { STEPS } from '@/store/general';
import Spinner from './Spinner.vue';
import HideFooter from '@/mixins/hide-footer';

const { mapState, mapMutations, mapGetters, mapActions } = require('vuex');

export default {
	name: 'BuildExperienceTab',

	components: {
		Experience,
		ExperienceHeader,
		Spinner
	},

	mixins: [HideFooter],

	data () {
		return {
			nbSlides: 0
		};
	},

	computed: {
		...mapGetters('talkingPoints', [
			'mapClass'
		]),
		...mapState({
			selectedLanguage: (state) => state.talkingPoints.selectedLanguage,
			texts: (state) => state.talkingPoints.texts,
			icons: (state) => state.talkingPoints.icons,
			translatedExperiences: (state) => state.talkingPoints.translatedExperiences,

			color: (state) => state.report.selectedColor,
			hasIcons: (state) => state.report.hasIcons,
			isInternal: (state) => state.report.isInternal,
			selectedSlide: (state) => state.report.selectedSlide,
			nbPerSlide: (state) => state.report.nbPerSlide,
			isEditMode: (state) => state.report.isEditMode,
			isRestoringReportPending: (state) => state.report.isRestoringReportPending
		}),
		isLandscapeMode () {
			return 'landscape' in this.$route.query;
		}
	},
	mounted () {
		this.loadCityscapesMaps();
	},
	watch: {
		nbSlides () {
			if (this.selectedSlide > this.nbSlides) this.setSelectedSlide(this.nbSlides);
		}
	},
	methods: {
		...mapActions('talkingPoints', [
			'loadCityscapesMaps'
		]),
		...mapMutations('talkingPoints', [
			'initiateSingleTalkingPoint'
		]),
		...mapMutations('general', [
			'changeStep'
		]),
		...mapMutations('report', [
			'setSelectedSlide'
		]),
		changeIcon (id, icon) {
			const idx = this.translatedExperiences.findIndex((item) => item.id === id);
			if (idx > -1) {
				this.$set(this.translatedExperiences[idx], 'surchargedIcon', icon);
			}
		},
		goToTalkingPointsList () {
			this.changeStep(STEPS.TALKING_POINTS);
		}
	}
};
</script>

<style lang="scss" >
@import "@/scss/_colors.scss";

$us-letter-aspect-ratio: 8.5in / 11in;
$font-size-to-page-width-ratio: 52px / 1224px;

.build-experience-tab__container {
  height: 900px;

  .row {
    height: 100%;
  }

  @media screen and (min-width: 1280px) {
    height: 1000px;
  }
  @media screen and (min-width: 1920px) {
    height: 1250px;
  }
}

.experience__container {
  background-color: #F3F4F5;

  .content{z-index: 100;}
}

.preview__container {
  background-color: #E8EBEE;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }

  .thumbnails {

    .thumbnail__empty {
      background-color: $gray-300 !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      p {
        color: $gray-600;
        margin: 0;
        font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
        font-size: 1.7em !important;
        font-weight: 500;
      }
    }

    .slides {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      > * {
        width: 120px;
        height: 120px / $us-letter-aspect-ratio;
        font-size: 120px * $font-size-to-page-width-ratio * 1.02;

        @media screen and (min-width: 1280px) {
          width: 160px;
          height: 160px / $us-letter-aspect-ratio;
          font-size: 160px * $font-size-to-page-width-ratio * 1.01;
        }
        @media screen and (min-width: 1920px) {
          width: 200px;
          height: 200px / $us-letter-aspect-ratio;
          font-size: 200px * $font-size-to-page-width-ratio;
        }

        background-color: white;
      }
    }
  }
}

.frame__container {

  display: flex;
  justify-content: center;
  padding-top: 102px ;

  &.preview-mode {
    padding-top: 0 ;
  }

  &.preview-mode > div {
    margin-top: 15px !important;
  }

  .frame {
    aspect-ratio: $us-letter-aspect-ratio;
    width: 400px;
    font-size: 400px * $font-size-to-page-width-ratio;

    @media screen and (min-width: 1280px) {
      width: 512px;
      font-size: 512px * $font-size-to-page-width-ratio;
    }
    @media screen and (min-width: 1920px) {
      width: 768px;
      font-size: 768px * $font-size-to-page-width-ratio;
    }

    background-color: #fff;
    box-shadow: 0px 0px 4px 1px $light-gray-04;
  }
}

</style>
