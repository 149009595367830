<template>

	<div id="js-nav--container" class="nav__container d-flex align-center" :class="[activeStep === STEPS.SURVEYS ? 'justify-center' : 'justify-space-between']">

		<div v-if="!!ACTIONS_BUTTONS[activeStep]" class="nav__actions nav__actions_left">
			<BuildExperienceActionButton
				:title="getButtonProperty('left', 'title')"
				:icon="getButtonProperty('left', 'icon')"
				@click="onClick(getButtonProperty('left', 'action'), $event)" />
		</div>

		<ul class="d-flex nav__steps">
			<li v-for="(item, index) in STEPS_BUTTONS" :key="index">
				<BuildExperienceStepButton
					:title="item.title"
					:status="getStatusFromStep(item.step)"
					@click="onGoToStep(item.step)" />

				<v-icon v-if="index < STEPS_BUTTONS.length - 1" class="arrow__forward">
					arrow_forward_ios
				</v-icon>
			</li>
		</ul>

		<div v-if="!!ACTIONS_BUTTONS[activeStep]" id="js-nav__right-actions" class="d-flex nav__actions nav__actions_right align-center">
			<div>
				<component
					:is="getButtonProperty('right', 'component')"
					:title="getButtonProperty('right', 'title')"
					:icon="getButtonProperty('right', 'icon')"
					@click="onClick(getButtonProperty('right', 'action'), $event)" />
			</div>

			<BuildExperienceSelectedTalkingPointsButton />
		</div>

	</div>

</template>

<script>
import BuildExperienceStepButton from './BuildExperienceStepButton.vue';
import BuildExperienceSelectedTalkingPointsButton from './BuildExperienceSelectedTalkingPointsButton.vue';
import BuildExperienceActionButton from './BuildExperienceActionButton.vue';
import BuildExperienceExportButton from './BuildExperienceExportButton.vue';
import { STEPS } from '@/store/general';

const { mapState, mapMutations, mapActions, mapGetters } = require('vuex');

const STEPS_AS_ARRAY = Object.values(STEPS);

const STEPS_BUTTONS = [
	{ step: STEPS.SURVEYS, title: 'Select Survey' },
	{ step: STEPS.TALKING_POINTS, title: 'Select Talking Points' },
	{ step: STEPS.REPORT, title: 'Assemble Report' }
];

const ACTIONS_BUTTONS = {
	[STEPS.TALKING_POINTS]: {
		left: { title: 'Back to Surveys', icon: 'arrow_back', action: 'onGoBack' },
		right: { title: 'Proceed to Report', icon: 'arrow_forward', action: 'onGoNext', component: 'BuildExperienceActionButton' }
	},
	[STEPS.REPORT]: {
		left: { title: 'Back to Talking Points', icon: 'arrow_back', action: 'onGoBack' },
		right: { title: 'Export report', icon: 'arrow_downward', action: 'onSelectExport', component: 'BuildExperienceExportButton' }
	}
};

export default {
	name: 'BuildExperienceNavigationHeader',

	data: function () {
		return {
			STEPS,
			ACTIONS_BUTTONS,
			STEPS_BUTTONS
		};
	},

	components: {
		BuildExperienceStepButton,
		BuildExperienceSelectedTalkingPointsButton,
		BuildExperienceActionButton,
		BuildExperienceExportButton
	},

	computed: {
		...mapState({
			surveyId: (state) => state.talkingPoints.selectedSurveyId,
			activeStep: (state) => state.general.activeStep
		}),
		...mapGetters('talkingPoints', [
			'selectedExperiencesMarketObjects'
		]),
		activeStepIndex: function () {
			return STEPS_AS_ARRAY.findIndex(val => val === this.activeStep);
		}
	},
	methods: {
		...mapActions('report', [
			'onSelectExport'
		]),
		...mapMutations('general', [
			'changeStep'
		]),
		...mapMutations('talkingPoints', [
			'setSelectedSurveyId'
		]),

		getButtonProperty (direction, property) {
			return this.ACTIONS_BUTTONS[this.activeStep][direction][property];
		},
		onGoToStep: function (step) {
			if (!this.surveyId) return;

			this.changeStep(step);
			if (step === STEPS.SURVEYS) {
				this.setSelectedSurveyId(null);
				this.$router.push({ path: '/build-experience' });
			}
		},
		onClick: function (methodName, event) {
			this[methodName](event);
		},
		onGoBack: function () {
			this.onGoToStep(STEPS_AS_ARRAY[this.activeStepIndex - 1]);
		},
		onGoNext: function () {
			this.onGoToStep(STEPS_AS_ARRAY[this.activeStepIndex + 1]);
		},
		getStatusFromStep: function (step) {
			const stepIndex = STEPS_AS_ARRAY.findIndex(val => val === step);
			const currentStepIndex = STEPS_AS_ARRAY.findIndex(val => val === this.activeStep);

			if (stepIndex === currentStepIndex) return 'ACTIVE';
			if (stepIndex > currentStepIndex) return 'INACTIVE';
			if (stepIndex < currentStepIndex) return 'VALID';
		}
	}

};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.nav__container {
  background-color: #fff;
  top: $header-height;
  left: 0;
  position: sticky;
	border-bottom: 1px solid $light-gray-03;
  z-index: 101;

  .nav__steps {
    padding: 16px 0 !important;

    .arrow__forward {
      margin: 0 10px;
      font-size: 14px;
      color: $gray-300;
    }
  }

  .nav__actions {

    &.nav__actions_left {
      padding: 22px;
      border-right: 1px solid $light-gray-03;
    }

    &.nav__actions_right {
      border-left: 1px solid $light-gray-03;

      & > div {
        background-color: $blue-100;
        padding: 22px;
      }
    }

  }

}

</style>
