<template>
	<v-dialog
		:value="showDialog"
		persistent
		max-width="500"
		style="padding:0;"
	>
		<v-card class="px-9 pt-4 pb-8">
			<div class="dialog-header">
				<v-spacer></v-spacer>
				<v-btn
					icon
					@click="onCancel"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			<div
				class="dialog-message"
				v-html="message"
			></div>
			<div>
				<v-btn
					@click="onCancel"
					class="_small"
				>
					Cancel
				</v-btn>
				<v-btn
					@click="onConfirm"
					class="_small second-button"
				>
					Yes
				</v-btn>
				<v-spacer></v-spacer>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'DialogConfirm',

	props: {
		showDialog: {
			type: Boolean,
			required: true
		},
		message: {
			type: String,
			required: true
		}
	},

	methods: {
		onCancel () {
			this.$emit('cancel');
		},
		onConfirm () {
			this.$emit('confirm');
		}
	}

};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.dialog-header {
	display: flex;
	margin-right: -24px;
}

.dialog-message {
	font-size: 18px;
	font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
	color: $green-06;
	margin-top: 8px;
	margin-bottom: 32px;
}

.second-button {
	margin-left: 16px;
}
</style>
