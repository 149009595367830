<template>
	<div class="filter-group pb-4">
		<v-row class="ma-0 pa-0">
			<v-col cols="12" class="ma-0 pa-0">
				<FilterGroupHeader
					label="Filter Talking Points"
					filtersGuide="TalkingPointsFiltersGuide"
					:selectedFilters="selectedFilters"
					:showSaveSelection="true"
					:selectionSaved="selectionSaved"
					@clearAll="onClearFilters"
					@clearFilter="unselectFilter"
					@toggleSaveSelection="onToggleSaveSelection"
				></FilterGroupHeader>
			</v-col>
			<v-col cols="12" class="ma-0 pa-0">
				<OneGroupSelect
					label="Market"
					:value="selectedMarkets"
					@input="setSelectedMarkets($event)"
					:items="markets"
					:activeItems="markets"
					:synonyms="marketSynonyms"
				></OneGroupSelect>
			</v-col>
			<v-col cols="12" class="mx-0 mt-2 mb-0 pa-0">
				<MultipleGroupsSelect
					label="Respondent Type"
					:value="selectedRespondentTypes"
					@input="setSelectedRespondentTypes($event)"
					:categories="respondentTypes"
					:activeCategories="respondentTypes"
					:synonyms="respondentTypeSynonyms"
				></MultipleGroupsSelect>
			</v-col>
			<v-col cols="12" class="mx-0 mt-2 mb-0 pa-0">
				<OneGroupSelect
					label="Topic"
					:value="selectedTopics"
					@input="setSelectedTopics($event)"
					:items="topics"
					:activeItems="topics"
					:synonyms="topicSynonyms"
				></OneGroupSelect>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import FilterGroupHeader from '@/components/FilterGroupHeader';
import OneGroupSelect from '@/components/OneGroupSelect';
import MultipleGroupsSelect from '@/components/MultipleGroupsSelect';

const { isState } = require('@/utils/data');
const { removeItemFromLists } = require('@/utils/filter');
const { STEPS } = require('@/store/general');
const { mapState, mapGetters, mapMutations, mapActions } = require('vuex');

export default {
	name: 'TalkingPointsFilters',

	components: {
		FilterGroupHeader,
		OneGroupSelect,
		MultipleGroupsSelect
	},

	data () {
		return {
			mode: {
				isStateMode: false,
				countryCode: ''
			}
		};
	},

	computed: {
		...mapState({
			markets: (state) => state.talkingPoints.markets,
			respondentTypes: (state) => state.talkingPoints.respondentTypes,
			topics: (state) => state.talkingPoints.topics,
			initialMarkets: (state) => state.talkingPoints.initialMarkets,
			initialRespondentTypes: (state) => state.talkingPoints.initialRespondentTypes,
			initialTopics: (state) => state.talkingPoints.initialTopics,
			selectedMarkets: (state) => state.talkingPoints.selectedMarkets,
			selectedRespondentTypes: (state) => state.talkingPoints.selectedRespondentTypes,
			selectedTopics: (state) => state.talkingPoints.selectedTopics,
			selectionSaved: (state) => state.talkingPoints.selectionSaved,
			activeStep: (state) => state.general.activeStep,
			previousStep: (state) => state.general.previousStep
		}),
		...mapGetters('general', [
			'marketSynonyms',
			'respondentTypeSynonyms',
			'topicSynonyms'
		]),
		selectedFilters () {
			return [...this.selectedMarkets, ...this.selectedRespondentTypes, ...this.selectedTopics];
		}
	},

	methods: {
		...mapMutations('talkingPoints', [
			'setSelectedMarkets',
			'setSelectedRespondentTypes',
			'setSelectedTopics',
			'setSelectionSaved'
		]),
		...mapActions('talkingPoints', [
			'loadSelects',
			'onToggleSaveSelection'
		]),
		...mapActions('general', [
			'loadSearchData'
		]),
		onClearFilters () {
			this.setSelectedMarkets([]);
			this.setSelectedRespondentTypes([]);
			this.setSelectedTopics([]);
		},
		unselectFilter (id) {
			const updatedFilters = removeItemFromLists(id, {
				markets: this.selectedMarkets,
				respondentTypes: this.selectedRespondentTypes,
				topics: this.selectedTopics
			});
			this.setSelectedMarkets(updatedFilters.markets);
			this.setSelectedRespondentTypes(updatedFilters.respondentTypes);
			this.setSelectedTopics(updatedFilters.topics);
		},
		checkMode () {
			if (!this.initialMarkets.length) return;

			const areStates = this.initialMarkets.every((market) => isState(market));
			const countryCodes = [...new Set(this.initialMarkets.map((market) => market.countryCode))];
			const belongToOneCountry = countryCodes.length === 1;
			const isStateMode = areStates && belongToOneCountry;

			if (isStateMode) {
				this.mode = {
					isStateMode,
					countryCode: countryCodes[0]
				};
			}
		},
		initFilters () {
			this.setSelectedMarkets(this.intersection(this.markets, this.initialMarkets));
			this.setSelectedRespondentTypes(this.intersection(
				this.respondentTypes.map((category) => category.items).flat(),
				this.initialRespondentTypes
			));
			this.setSelectedTopics(this.intersection(this.topics, this.initialTopics));
		},
		intersection (objects1, objects2) {
			const ids2 = objects2.map((obj) => obj.id);
			return objects1.filter((obj) => ids2.includes(obj.id));
		}
	},

	watch: {
		selectedFilters () {
			this.setSelectionSaved(false);
		}
	},

	async beforeMount () {
		if (this.previousStep !== STEPS.REPORT) {
			this.checkMode();
			await this.loadSelects(this.mode);
			this.initFilters();
			if (!this.marketSynonyms.length) {
				this.loadSearchData();
			}
		}
	}
};
</script>
