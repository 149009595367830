<template>
	<v-icon v-if="value" :color="checkedColor" >mdi-square</v-icon>
	<v-icon v-else :color="uncheckedColor" :class="{'active-checkbox': !disabled}">mdi-square-outline</v-icon>
</template>

<script>
export default {
	name: 'CheckBox',
	props: {
		value: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		uncheckedColor () {
			return this.disabled ? 'gray300' : 'gray600';
		},
		checkedColor () {
			return this.disabled ? 'gray300' : 'metaBlue';
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.active-checkbox {
  &:hover {
    background-color: $gray-100;
  }
}
</style>
