<template>
	<div class="filter-group pb-4">
		<v-row class="ma-0 pa-0">
			<v-col cols="12" class="ma-0 pa-0">
				<FilterGroupHeader
					label="Filter Surveys"
					filtersGuide="SurveysFiltersGuide"
					:selectedFilters="selectedFilters"
					@clearAll="onClearFilters"
					@clearFilter="unselectFilter"
				></FilterGroupHeader>
			</v-col>
			<v-col cols="12" class="ma-0 pa-0">
				<OneGroupSelect
					label="Market"
					:value="selectedMarkets"
					@input="setSelectedMarkets($event)"
					@change="onChangeSelect"
					@sendEvent="sendGaEventByType($event, 'M')"
					:items="allMarkets"
					:activeItems="allActiveMarkets"
					:synonyms="marketSynonyms"
				></OneGroupSelect>
			</v-col>
			<v-col cols="12" class="mx-0 mt-2 mb-0 pa-0">
				<MultipleGroupsSelect
					label="Respondent Type"
					:value="selectedRespondentTypes"
					@input="setSelectedRespondentTypes($event)"
					@change="onChangeSelect"
					@sendEvent="sendGaEventByType($event, 'R')"
					:equivalent-items="equivalentFilters.respondents"
					:categories="respondentTypes"
					:activeCategories="activeRespondentTypes"
					:synonyms="respondentTypeSynonyms"
				></MultipleGroupsSelect>
			</v-col>
			<v-col cols="12" class="mx-0 mt-2 mb-0 pa-0">
				<OneGroupSelect
					label="Topic"
					:value="selectedTopics"
					@input="setSelectedTopics($event)"
					@change="onChangeSelect"
					@sendEvent="sendGaEventByType($event, 'T')"
					:items="topics"
					:activeItems="activeTopics"
					:synonyms="topicSynonyms"
				></OneGroupSelect>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import FilterGroupHeader from '@/components/FilterGroupHeader';
import OneGroupSelect from '@/components/OneGroupSelect';
import MultipleGroupsSelect from '@/components/MultipleGroupsSelect';

import Ga from '@/mixins/ga';
import GaEvent from '@/constants/ga-event';

const { mapState, mapGetters, mapMutations, mapActions } = require('vuex');
const { removeItemFromLists, formatMarkets, formatActiveMarkets } = require('@/utils/filter');

export default {

	mixins: [Ga],

	components: {
		FilterGroupHeader,
		OneGroupSelect,
		MultipleGroupsSelect
	},

	computed: {
		...mapState({
			markets: (state) => state.surveys.markets,
			activeMarkets: (state) => state.surveys.activeMarkets,
			selectedMarkets: (state) => state.surveys.selectedMarkets,
			initialMarkets: (state) => state.surveys.initialMarkets,

			respondentTypes: (state) => state.surveys.respondentTypes,
			activeRespondentTypes: (state) => state.surveys.activeRespondentTypes,
			selectedRespondentTypes: (state) => state.surveys.selectedRespondentTypes,
			initialRespondentTypes: (state) => state.surveys.initialRespondentTypes,

			topics: (state) => state.surveys.topics,
			activeTopics: (state) => state.surveys.activeTopics,
			selectedTopics: (state) => state.surveys.selectedTopics,
			initialTopics: (state) => state.surveys.initialTopics,

			totalSurveys: (state) => state.surveys.totalSurveys,

			equivalentFilters: (state) => state.general.equivalentFilters
		}),
		...mapGetters('general', [
			'marketSynonyms',
			'respondentTypeSynonyms',
			'topicSynonyms'
		]),
		allMarkets: function () {
			return formatMarkets(this.markets);
		},
		allActiveMarkets: function () {
			return formatActiveMarkets(this.activeMarkets, this.selectedMarkets);
		},
		selectedFilters () {
			return [...this.selectedMarkets, ...this.selectedRespondentTypes, ...this.selectedTopics];
		}
	},

	methods: {
		...mapMutations('surveys', [
			'setMarkets',
			'setActiveMarkets',
			'setSelectedMarkets',

			'setRespondentTypes',
			'setActiveRespondentTypes',
			'setSelectedRespondentTypes',

			'setTopics',
			'setActiveTopics',
			'setSelectedTopics'
		]),
		...mapActions('surveys', [
			'loadAvailableFilters'
		]),
		...mapActions('general', [
			'loadSearchData',
			'loadEquivalentFilters'
		]),
		async unselectFilter (id) {
			const updatedFilters = removeItemFromLists(id, {
				markets: this.selectedMarkets,
				respondentTypes: this.selectedRespondentTypes,
				topics: this.selectedTopics
			});
			this.setSelectedMarkets(updatedFilters.markets);
			this.setSelectedRespondentTypes(updatedFilters.respondentTypes);
			this.setSelectedTopics(updatedFilters.topics);

			await this.onChangeSelect();
		},
		async onClearFilters () {
			this.resetFilters();
			await this.onShowResults();
		},
		resetFilters () {
			this.initSelectedFilters();
			this.initActiveFilters();
		},
		initSelectedFilters () {
			this.setSelectedMarkets([]);
			this.setSelectedRespondentTypes([]);
			this.setSelectedTopics([]);
		},
		initActiveFilters () {
			this.setActiveMarkets(this.markets);
			this.setActiveRespondentTypes(this.respondentTypes);
			this.setActiveTopics(this.topics);
		},
		initFilters () {
			this.setSelectedMarkets(this.initialMarkets);
			this.setSelectedRespondentTypes(this.initialRespondentTypes);
			this.setSelectedTopics(this.initialTopics);
		},
		async onChangeSelect () {
			await this.loadAvailableFilters();
			await this.onShowResults();
		},
		initSelects (markets, respondentTypes, topics) {
			this.setMarkets(markets);
			this.setRespondentTypes(respondentTypes);
			this.setTopics(topics);
		},
		async onShowResults () {
			//this.sendGaEvent(GaEvent.showResult, GaEvent.filter);
			this.$emit('changeFilter');
		},
		async sendGaEventByType (items, type) {
			for (let index = 0; index < items.length; index++) {
				const item = items[index];
				await this.sendGaEvent(GaEvent.explorePageFilters, `${type}|${item.label}`);
			}
		}
	},

	async beforeMount () {
		if (!this.totalSurveys) {
			await this.loadAvailableFilters();
			this.initSelects(this.activeMarkets, this.activeRespondentTypes, this.activeTopics);
			this.loadSearchData();

			if (!Object.keys(this.equivalentFilters).length) {
				this.loadEquivalentFilters();
			}

			this.initFilters();
			this.onChangeSelect();
		}
	}
};
</script>
