<template>
	<div class="check-list px-2">
		<div v-if="label" class="blue600--text">{{ label }}</div>
		<ul>
			<li
				class="check-list-item py-2"
				v-for="(item, index) in sortedItems"
				:key="index"
				:class="{'check-list-item_inactive': isInactive(item)}"
				@click="toggleItem(item)"
			>
				<CheckBox
					:value="isSelected(item)"
					:disabled="isInactive(item)"
				></CheckBox>
				{{ item[itemText] }}
			</li>
		</ul>
	</div>
</template>

<script>
import CheckBox from './CheckBox';

const { sortInactive } = require('@/utils/data');

export default {
	name: 'ItemsList',

	components: {
		CheckBox
	},

	props: {
		value: {
			type: Array
		},
		label: {
			type: String
		},
		items: {
			type: Array,
			default: () => []
		},
		activeItems: {
			type: Array,
			default: () => []
		},
		equivalentItems: {
			type: Array
		},
		itemText: {
			type: String,
			default: 'label'
		},
		itemValue: {
			type: String,
			default: 'id'
		}
	},

	computed: {
		selectedItems () {
			if (!this.value) return [];
			return [...this.value];
		},
		sortedItems () {
			return [...this.items].sort((a, b) => sortInactive(this.isInactive(a), this.isInactive(b)));
		}
	},

	methods: {
		isSelected (item) {
			return this.selectedItems.map(e => e[this.itemValue]).includes(item[this.itemValue]);
		},
		isInactive (item) {
			return !this.activeItems
				.map(e => e && e[this.itemValue]?.toLowerCase())
				.includes(item && item[this.itemValue]?.toLowerCase());
		},
		toggleItem (item) {
			if (this.isInactive(item)) return;

			const equivalentItems = (this.equivalentItems?.findIndex(i => i[this.itemValue] === item[this.itemValue]) > -1)
				? this.equivalentItems
				: [item];

			const index = this.selectedItems.findIndex((elt) => elt[this.itemValue] === item[this.itemValue]);
			const newSelectedItems = index === -1
				? [...this.selectedItems, ...equivalentItems]
				: [...this.selectedItems.slice(0, index), ...this.selectedItems.slice(index+1)];
			this.$emit('input', newSelectedItems);
			this.$emit('change');
			this.$emit('sendEvent', [item]);
		}
	}
};
</script>

<style lang='scss' scoped>
@import "@/scss/_colors.scss";

.check-list-item {
  color: $gray-600;
}

.check-list-item_inactive {
  color: $gray-300;
  background-color: $gray-100;
}
</style>
