<template>
	<div class="multiple-groups-select">
		<FilterHeader
			v-model="showItems"
			:label="label"
			@search="search = $event"
		></FilterHeader>
		<div class="menu mt-2" v-if="showItems">
			<CheckList
				v-for="category in categories"
				:key="category.label"
				:label="category.label"
				:value="getSelectedItemsByCategory(category.label)"
				:items="getFilteredItemsByCategory(category.label)"
				:activeItems="getFilteredActiveItemsByCategory(category.label)"
				:equivalentItems="equivalentItems"
				@input="updateSelectedItems($event, category.label)"
				@change="$emit('change')"
				@sendEvent="$emit('sendEvent', $event)"
			></CheckList>
		</div>

	</div>
</template>

<script>
import FilterHeader from './FilterHeader';
import CheckList from './CheckList';

const { filterItems } = require('@/utils/search');

export default {
	name: 'MultipleGroupsSelect',

	components: {
		FilterHeader,
		CheckList
	},

	props: {
		value: {
			type: Array
		},
		label: {
			type: String
		},
		categories: {
			type: Array,
			default: () => []
		},
		activeCategories: {
			type: Array,
			default: () => []
		},
		equivalentItems: {
			type: Array
		},
		synonyms: {
			type: Array,
			default: () => []
		}
	},

	data () {
		return {
			showItems: true,
			search: '',
			selectedCategories: []
		};
	},

	computed: {
		filteredCategories () {
			if (!this.search) return this.categories;
			return this.categories.map((c) => ({ ...c, items: filterItems(c.items, this.synonyms, this.search) }));
		},
		filteredActiveCategories () {
			if (!this.search) return this.activeCategories;
			return this.activeCategories.map((c) => ({ ...c, items: filterItems(c.items, this.synonyms, this.search) }));
		}
	},

	methods: {
		updateSelectedCategories () {
			const selectedIds = this.value.map((item) => item.id);
			this.selectedCategories = this.categories.map((c) => ({
				...c,
				items: c.items.filter((item) => selectedIds.includes(item.id))
			}));
		},
		getItemsByCategory (categories, label) {
			return categories.find((c) => c.label === label)?.items || [];
		},
		getSelectedItemsByCategory (label) {
			return this.getItemsByCategory(this.selectedCategories, label);
		},
		getFilteredItemsByCategory (label) {
			return this.getItemsByCategory(this.filteredCategories, label);
		},
		getFilteredActiveItemsByCategory (label) {
			return this.getItemsByCategory(this.filteredActiveCategories, label);
		},
		updateSelectedItems (selected, label) {
			const matched = this.selectedCategories.find((c) => c.label === label);
			if (matched) {
				matched.items = selected;
			}
			this.$emit('input', this.selectedCategories.map((c) => c.items).flat());
		}
	},

	mounted () {
		if (this.value && this.categories.length) this.updateSelectedCategories();
	},

	watch: {
		value () {
			if (this.value && this.categories.length) this.updateSelectedCategories();
		},
		categories () {
			if (this.value && this.categories.length && !this.selectedCategories.length) this.updateSelectedCategories();
		}
	}
};
</script>

<style lang="scss" scoped>
.multiple-groups-select {
  .menu {
    max-height: 242px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
  }
}
</style>
