<template>
	<div>
		<p class="mt-2 mb-0">SELECT FILTERS TO</p>
		<ul>
			<li>Eliminate talking points that do not match selected filter(s)</li>
		</ul>
		<p class="mt-2 mb-0">CLEARING ALL FILTERS WILL</p>
		<ul>
			<li>Settle back all filters to a selectable state and show all talking points of the survey</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'TalkingPointsFiltersGuide'
};
</script>

