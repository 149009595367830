<template>

	<v-container fluid pa-0>

		<v-row no-gutters>

			<v-col v-if="activeStep !== STEPS.REPORT" class="filters mb-4 h-100" cols="12" sm="3">

				<SurveysFilters v-if="activeStep === STEPS.SURVEYS" @changeFilter="loadSurveys()" />

				<TalkingPointsFilters v-if="activeStep === STEPS.TALKING_POINTS" />

			</v-col>

			<v-col cols="12" :sm="activeStep === STEPS.REPORT ? 12 : 9">

				<BuildExperienceNavigationHeader />

				<div class="step-container" :class="{ '_report-step': activeStep === STEPS.REPORT }">
					<SurveysList v-if="activeStep === STEPS.SURVEYS" @onSelectSurvey="onSelectSurvey($event)" />

					<TalkingPointsList v-else-if="activeStep === STEPS.TALKING_POINTS" />

					<BuildExperienceTab v-else-if="activeStep === STEPS.REPORT" />
				</div>

			</v-col>

		</v-row>

		<BuildExperiencePopups/>

	</v-container>

</template>

<script>

import BuildExperienceNavigationHeader from '@/components/BuildExperienceNavigationHeader';
import SurveysFilters from '@/components/SurveysFilters.vue';
import SurveysList from '@/components/SurveysList';
import TalkingPointsFilters from '@/components/TalkingPointsFilters';
import TalkingPointsList from '@/components/TalkingPointsList';
import BuildExperienceTab from '@/components/BuildExperienceTab';
import { STEPS } from '@/store/general';
import BuildExperiencePopups from '../components/BuildExperiencePopups.vue';

const { mapState, mapActions, mapMutations } = require('vuex');

export default {

	name: 'BuildExperience',

	data: function () {
		return {
			STEPS,
			prevRoute: null
		};
	},

	components: {
		BuildExperienceNavigationHeader,
		BuildExperienceTab,
		BuildExperiencePopups,
		SurveysFilters,
		SurveysList,
		TalkingPointsFilters,
		TalkingPointsList
	},

	computed: {
		...mapState({
			selectedMarkets: (state) => state.surveys.selectedMarkets,
			selectedRespondentTypes: (state) => state.surveys.selectedRespondentTypes,
			selectedTopics: (state) => state.surveys.selectedTopics,
			surveyId: (state) => state.talkingPoints.selectedSurveyId,
			activeStep: (state) => state.general.activeStep
		})
	},

	destroyed: function () {
		this.resetReportState();
		this.resetSurveysState();
		this.resetTalkingPointsState();
		this.resetStep();
	},

	methods: {
		...mapMutations('general', [
			'changeStep',
			'resetStep'
		]),
		...mapActions('surveys', [
			'resetSurveysState',
			'loadSurveys'
		]),
		...mapMutations('talkingPoints', [
			'setInitialFilters'
		]),
		...mapActions('talkingPoints', [
			'selectSurvey',
			'resetTalkingPointsState'
		]),
		...mapActions('report', [
			'resetReportState'
		]),

		initialize () {
			const { surveyId } = this.$route.query;

			if (surveyId && !this.prevRoute.name) {
				this.onSelectSurvey({ surveyId });
			}
		},

		onSelectSurvey ({ surveyId }) {
			this.setInitialFilters({
				markets: this.selectedMarkets,
				respondents: this.selectedRespondentTypes,
				topics: this.selectedTopics
			});
			this.selectSurvey(surveyId);
			this.changeStep(STEPS.TALKING_POINTS);
		}

	},

	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.prevRoute = from;

			vm.initialize();
		});
	}

};

</script>

<style lang="scss" scoped>
  .row {
    height: 100%;
  }

  .filters {
    margin-top: $header-height;
  }

  .step-container {
    margin-top: $header-height;
    overflow: hidden;

    &._report-step {

      .container > div { overflow: hidden; }

      div.col-sm-10.col-sm-12 { height: 100%; }
    }
  }
</style>
