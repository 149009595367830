<template>

	<div class="button-container">

		<a @click="$emit('click')" class="font-weight-normal d-flex align-center">
			<v-icon class="rounded-circle">
				{{ icon }}
			</v-icon>

			<span class="ml-2">
				{{ title }}
			</span>
		</a>

	</div>

</template>

<script>

export default {
	name: 'BuildExperienceActionButton',

	props: {
		title: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.button-container {

  a {
    color: $meta-blue  !important;

    .v-icon {
      font-size: 1.2em;
      color: $meta-blue  !important;
      border: 1px solid $meta-blue;
    }
  }

}
</style>
