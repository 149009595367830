<template>

	<div class="surveys-list pt-4 px-8">

		<header class="surveys-list-actions d-flex align-center mb-4">

			<div class="surveys-list-actions-title">
				Surveys - {{ filteredAndSortedSurveysWithWave.length }} of {{ totalSurveys }}
			</div>

			<v-spacer />

			<div class="surveys-list-actions-grid mr-6">
				<a @click="isGrid = !isGrid" class="font-weight-normal">
					<v-icon class="material-icons-outlined">
						{{ !isGrid ? 'grid_view' : 'view_list' }}
					</v-icon>
				</a>
			</div>

			<FiltersButtonList
				v-model="sort.value"
				:items="sort.items"
				@input="onChangeSort"
			/>
		</header>

		<v-container v-if="isGrid" fluid ma-0 pa-0>

			<v-row dense>
				<v-col v-for="(survey, index) in displayedSurveys" :key="index" class="mb-4" cols="12" sm="6"
					md="4" lg="3" xl="3">

					<SurveyCard :survey="survey" :loading="loadingSurveys" @onSelectSurvey="onSelectSurvey($event)"></SurveyCard>

				</v-col>
			</v-row>

		</v-container>

		<div v-else class="surveys-list-content-list">
			<ul>
				<li class="list-item" v-for="(survey, index) in filteredAndSortedSurveysWithWave" :key="index" @click="onSelectSurvey({ surveyId: survey.id, surveyTitle: survey.title })">
					<strong class="item-title pt-3">{{ survey.title }}</strong>

					<div class="item-infos">
						<span> {{ survey.date }} </span>
						<span v-if="survey.wave"> Wave {{ survey.wave }} </span>
						<span v-if="survey.talkingPointsTotal">
							{{ numberWithCommas(survey.talkingPointsTotal) }}
							Talking Points
						</span>
					</div>

					<p class="item-description my-3">
						{{survey.description}}
					</p>

					<v-icon class="arrow__forward">
						arrow_forward_ios
					</v-icon>
				</li>
			</ul>
		</div>

	</div>

</template>

<script>
import FiltersButtonList from '@/components/FiltersButtonList';
import SurveyCard from '@/components/SurveyCard';

const { mapState, mapActions } = require('vuex');
const { numberWithCommas } = require('@/utils/helpers');
const { formatSurveyWithWave } = require('@/utils/data');
const { filterUserRecentOrTrendingDocuments } = require('../utils/userRecentOrTrendingDocuments');


export default {
	components: {
		FiltersButtonList,
		SurveyCard
	},

	data: function () {
		return {
			isGrid: true,
			sort: {
				value: null,
				items: [
					{ id: 'added', text: 'Recently added' },
					{ id: 'viewed', text: 'Recently viewed' },
					{ id: 'trending', text: 'Trending' }
				]
			}
		};
	},

	computed: {
		...mapState({
			loadingSurveys: (state) => state.surveys.loadingSurveys,
			totalSurveys: (state) => state.surveys.totalSurveys,
			filteredSurveys: (state) => state.surveys.filteredSurveys,
			userRecentSurveys: (state) => state.surveys.userRecentSurveys,
			trendingSurveys: (state) => state.surveys.trendingSurveys
		}),
		displayedSurveys () {
			return this.loadingSurveys ? new Array(8) : this.filteredAndSortedSurveysWithWave;
		},
		filteredAndSortedSurveysWithWave () {
			let results;

			if (this.sort.value?.id === 'viewed') {
				results = filterUserRecentOrTrendingDocuments({
					userRecentOrTrendingdocuments: this.userRecentSurveys,
					availableDocuments: this.filteredSurveys,
					idField: 'id',
					limit: 30
				});
			} else if (this.sort.value?.id === 'trending') {
				results = filterUserRecentOrTrendingDocuments({
					userRecentOrTrendingdocuments: this.trendingSurveys,
					availableDocuments: this.filteredSurveys,
					idField: 'id',
					limit: 30
				});
			} else {
				results = this.filteredSurveys;
			}

			return results.map(formatSurveyWithWave);
		}
	},

	beforeMount () {
		this.loadUserRecentSurveys();
		this.loadTrendingSurveys();
	},

	methods: {
		...mapActions('surveys', [
			'loadUserRecentSurveys',
			'loadTrendingSurveys'
		]),
		numberWithCommas,
		onChangeSort () {
			if (this.sort.value?.id === 'viewed') {
				this.loadUserRecentSurveys();
			} else if (this.sort.value?.id === 'trending') {
				this.loadTrendingSurveys();
			}
		},
		onSelectSurvey (survey) {
			this.$emit('onSelectSurvey', survey);
		}
	}
};

</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.surveys-list {
  .surveys-list-actions {
    .filters-button {
      color: $meta-gray;
      font-size: inherit;
    }
  }
}

.surveys-list-actions-title {
  color: $meta-gray;
}

.surveys-list-actions-grid {
  .v-icon {
    color: $meta-gray !important;
  }
}

.surveys-list-content-list {
  margin-bottom: -32px;

  .list-item {
    display: flex;
    flex-direction: column;
    margin: 0 -33px;
    padding: 0 32px;
    cursor: pointer;
    background-color: #fff;
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;

    .arrow__forward {
      position: absolute;
      top: calc(50% - 12px);
      right: calc(16px + 12px);
      color: $blue-600 !important;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      background-color: $blue-100;

      .arrow__forward {
        right: 16px;
      }
    }

    .item-title {
      color: $blue-600 !important;
      font-weight: bold !important;
      font-size: 22px;
      font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
    }

    .item-infos span {
      color: $meta-gray;
      font-size: 0.9em;

      &~span {
        padding-left: 11px;
      }

      &:last-child::after {
        display: none;
      }

      &::after {
        content: "";
        height: 0.6em;
        width: 1px;
        position: absolute;
        right: -5px;
        top: 50%;
        margin-top: -0.3em;
        background-color: $gray-600;
      }
    }

    .item-description {
      color: $meta-gray;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:last-child {
      border-bottom: 1px solid $gray-300;
    }

  }

}
</style>
