<template>

	<div class="content">

		<header class="d-flex _header" :class="{ _inactive: !experiences.length }">

			<ul class="tab" :class="{ 'preview-mode': !isEditMode }">

				<li class="tab-item" v-for="tab of tabs" :key="tab.id" :class="{ 'tab-item--active': (activeTab === tab.id && isEditMode) }">
					<a @click="onSetActiveTab(tab.id)">{{ tab.title }}</a>
					<ul class="tab-item--subtab">
						<li class="tab-subtab--item" v-for="(filter, index) of tab.filters" :key="index">
							<Select
								:items="getTabValue(filter.items) || []"
								:icon="filter.icon"
								:itemText="filter.itemText"
								@onselect="onSelect(filter.onSelect, $event)"
								bottom />

							<div class="mt-2">
								<span class="tab-subtab--item--title">
									{{ filter.title }}:
								</span>
								{{ getTabValue(filter.value) }}
							</div>
						</li>
					</ul>
				</li>

			</ul>

			<div class="edit-preview__container d-flex align-center">
				<div>
					<span class="edit-preview__name">Mode</span>
					<v-switch :input-value="!isEditMode" @change="toggleIsEditMode()" dense inset color="metaBlue"/>
				</div>

				<span class="edit-preview__label">
					{{ isEditMode ? 'Edit' : 'Preview' }}
				</span>
			</div>

		</header>

	</div>

</template>

<script>

import Select from '@/components/Select';

const { mapState, mapMutations, mapGetters, mapActions } = require('vuex');

export default {
	name: 'ExperienceHeader',

	components: {
		Select
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			activeTab: 'style',
			previewModes: ['Internal', 'External'],
			displayIconModes: ['Display icons', 'Hide icons'],
			tabs: [
				{
					id: 'style', title: 'Style', filters: [
						{ items: 'optionsForMaps', icon: 'icon-UI-experience-map-cityscape', title: 'Map', onSelect: 'setMapOption', value: 'selectedMapOptionAsText' },
						{ items: 'displayIconModes', icon: 'icon-UI-experience-add-remove-icons', title: 'Icons', onSelect: 'toggleIcons', value: 'hasIconsAsOnOff' }
					]
				},
				{
					id: 'audience', title: 'Audience', filters: [
						{ items: 'previewModes', icon: 'icon-UI-experience-internal-external', title: 'Audience', onSelect: 'toggleAudience', value: 'isInternalAsText' }
					]
				},
				{
					id: 'language', title: 'Language', filters: [
						{ items: 'languages', icon: 'icon-UI-experience-language', title: 'Language', onSelect: 'changeLanguage', itemText: 'name', value: 'selectedLanguageName' }
					]
				}
			]
		};
	},

	methods: {
		...mapActions('talkingPoints', [
			'updateTranslations'
		]),
		...mapMutations('talkingPoints', [
			'swapExperiences',
			'setTexts',
			'setSelectedLanguage'
		]),
		...mapMutations('report', [
			'toggleIsEditMode',
			'setMapOption',
			'toggleIcons',
			'toggleAudience'
		]),
		getTabValue (key) {
			return this[key];
		},
		onSelect (methodName, event) {
			this[methodName](event);
		},
		onSetActiveTab (tab) {
			this.activeTab = tab;
		},
		changeLanguage (language) {
			this.setSelectedLanguage(language);
			this.updateTranslations();
		}

	},
	computed: {
		...mapGetters('talkingPoints', [
			'optionsForMaps'
		]),
		...mapState({
			languages: (state) => state.talkingPoints.languages,
			selectedLanguage: (state) => state.talkingPoints.selectedLanguage,
			defaultTexts: (state) => state.talkingPoints.defaultTexts,
			experiences: (state) => state.talkingPoints.experiences,

			selectedColor: (state) => state.report.selectedColor,
			hasIcons: (state) => state.report.hasIcons,
			isInternal: (state) => state.report.isInternal,
			selectedMapOption: (state) => state.report.selectedMapOption,

			selectedLanguageName: function () {
				return this.selectedLanguage.name || '';
			},
			hasIconsAsOnOff: function () {
				return this.hasIcons ? 'On' : 'Off';
			},
			isInternalAsText: function () {
				return this.isInternal ? 'Internal' : 'External';
			},
			selectedMapOptionAsText: function () {
				const value = this.selectedMapOption === 'none of the above' ? 'none' : this.selectedMapOption;

				return value.charAt(0).toUpperCase() + value.slice(1);
			}
		})
	},

	watch: {
		defaultTexts () {
			this.setTexts(this.defaultTexts);
		}
	}
};
</script>

<style lang="scss" >
@import "@/scss/_colors.scss";

.edit-preview__container{
  position: absolute;
  right: 50px;
  top: 2px;
  min-width: 120px;
  transform: scale(0.9);

  .edit-preview__name {
    font-size: 0.7em;
    position: absolute;
    color: $meta-gray;
  }

  .edit-preview__label {
    color: $meta-blue;
  }
}

.tab {
  width: 100%;
  border-bottom: 1px solid $gray-600;
  display: flex;
  justify-content: flex-start;

  &.preview-mode {

    .tab-item a {
      padding-bottom: 4px !important;

      &:hover {
        color: $gray-600 !important;
      }
    }

    .tab-item--subtab {
      display: none !important;
    }

  }

  .tab-item {
    display: block;
    margin-bottom: -1px;
    padding: 16px 24px calc(16px - 4px) 24px;
    border: 1px solid $gray-600;
    border-top: 0;
    border-left: 0;
    position: static;

    a {
      color: $meta-gray !important;
      &:hover {
        color: $meta-blue !important;
      }
      display: block;
    }

    .tab-item--subtab{
      display: none;
      position: absolute;
      top: calc(100% + 1px);
      width: 100%;
      left: 0;
      background: #E3E6EA;
      padding: 16px;
      border-bottom: 1px solid $gray-600;

      .tab-subtab--item{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 16px 16px;
        border: 0;

        button {
          border: 1px solid $meta-gray;
          border-radius: 50%;
          padding: 18px;
        }

        .tab-subtab--item--title{
          margin-top: 4px;
          font-weight: bold;
        }

        i[class^="icon"] {
          font-size: 34px;
          color: $meta-gray;
        }
      }
    }

    &.tab-item--active {
      background: #E3E6EA;
      border-bottom-color: #E3E6EA;

      a {
        color: $meta-blue !important;
        padding-bottom: 4px;
        border-bottom: 2px solid $meta-blue;
      }

      .tab-item--subtab { display: flex; }

    }

  }

}
</style>
