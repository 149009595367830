<template>
	<div class="chip">
		{{ label }}
		<v-icon small @click="$emit('close')">mdi-close</v-icon>
	</div>
</template>

<script>
export default {
	name: 'Chip',

	props: {
		label: {
			type: String
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.chip {
  display: inline-block;
  padding: 4px;
  margin-right: 8px;
  background-color: $blue-100;
  color: $meta-blue;
  font-weight: bold;

  .v-icon {
    color: $meta-gray !important;
    background-color: transparent !important;
    border-style: none !important;
  }
}
</style>

