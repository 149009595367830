<template>
	<div>
		<p class="mt-2 mb-0">SELECT FILTERS TO</p>
		<ul>
			<li>Eliminate surveys that do not match selected filter(s)</li>
			<li>Narrow the talking points that will be displayed once a survey is opened</li>
			<li>Grey out filters that are not cross-compatible</li>
		</ul>
		<p class="mt-2 mb-0">CLEARING ALL FILTERS WILL</p>
		<ul>
			<li>Settle back all filters to a selectable state and show all surveys again</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'SurveysFiltersGuide'
};
</script>

