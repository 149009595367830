<template>

	<v-form class="usage-form" ref="usageForm">
		<v-container fluid>
			<v-row>
				<div class="usage-form-message">
					Please provide information about how you will use the generated document(s)
				</div>
			</v-row>
			<div class="actions">

				<v-text-field
					class="form-input"
					label="Where will this collateral be used? *"
					color="metaGray"
					v-model="form.useCase"
					required
				></v-text-field>

				<v-text-field
					class="form-input"
					label="Who is the expected audience? *"
					color="metaGray"
					v-model="form.expectedAudience"
					required
				></v-text-field>

				<v-text-field
					class="form-input"
					type="number"
					id="audienceSize"
					:rules="[v => (v && v > 0)]"
					label="What size is the expected audience? *"
					color="metaGray"
					v-model="form.audienceSize"
					required
				></v-text-field>
				<v-tooltip
					:disabled="filledForm"
					top
					content-class="usage-form-tooltip">
					<template v-slot:activator="{ on }">
						<div v-on="on">
							<v-btn
								:disabled="!filledForm"
								class="action"
								@click="submitAndRemoveFromView">
								<span
									class="button-message"
									:class="!filledForm? 'disabled-message' : ''">
									Proceed
								</span>
							</v-btn>
						</div>
					</template>
					<span>Please fill in all fields to proceed</span>
				</v-tooltip>

			</div>
		</v-container>
	</v-form>

</template>

<script>

import Ga from '@/mixins/ga';
import GaEvent from '@/constants/ga-event';
const api = require('@/utils/api');

export default {
	name: 'UsageForm',
	props: {
		markets: {
			type: Array
		}
	},
	data () {
		return {
			form: {
				useCase: '',
				expectedAudience: '',
				audienceSize: ''
			}
		};
	},

	mixins: [Ga],

	computed: {
		filledForm () {
			return !!(this.form.useCase && this.form.expectedAudience && this.form.audienceSize);
		}
	},

	methods: {
		clearForm () {
			this.form = {
				useCase: '',
				expectedAudience: '',
				audienceSize: ''
			};
		},
		async submitAndRemoveFromView () {
			this.form.audienceSize = Number(this.form.audienceSize);
			this.$refs.usageForm.validate();
			await api.submitUsageData({ ...this.form, markets: this.markets });
			this.sendGaEvent(GaEvent.downloadReport, null, null, this.form.audienceSize);
			this.clearForm();
			this.$emit('popupDone');
		}
	},

	async mounted () {
		const audienceSize = document.getElementById('audienceSize');
		const invalidChars = [
			'-',
			'+',
			'e'
		];
		audienceSize?.addEventListener('keydown', function (e) {
			if (invalidChars.includes(e.key)) {
				e.preventDefault();
			}
		});
	}

};

</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.form-input {
	padding: 0 20px 0 20px;
	color: $blue-600 !important;
}

.actions {
	button.action {
    border-radius: 0% !important;
		margin: 20px 10px 10px 10px;
		background-color: $blue-100 !important;
    color: $meta-blue !important;
	}
}

.usage-form-message {
	font-size: 18px;
	font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
	color: $blue-600;
  font-weight: bold;
	padding: 12px 30px 32px 30px;
	text-align: justify;
}

.disabled-message {
	color: $gray-600;
}

.usage-form {
  .v-text-field .v-label {
    color: $blue-600;
  }

  .v-text-field {
    input {
      color: $meta-gray !important;
      font-weight: 400;
    }
  }
}

.usage-form-tooltip {
	background: #ebf2e68c !important;
	color: $green-09 !important;
	font-weight: 700;
	font-size: 15px !important;
	box-shadow: 0px 3px 6px #00000029;
	opacity: 1 !important;
}

/* hide spin button on number field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

</style>
