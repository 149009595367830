<template>
	<div>
		<p class="mt-2 mb-0">SELECT FILTERS TO</p>
		<ul>
			<li>Eliminate documents that do not match selected filter(s)</li>
			<li>Grey out filters that are not cross-compatible</li>
		</ul>
		<p class="mt-2 mb-0">CLEARING ALL FILTERS WILL</p>
		<ul>
			<li>Settle back all filters to a selectable state and show all documents again</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'FilesFiltersGuide'
};
</script>

